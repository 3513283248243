
// TODO: move to admin only file
// restrictSelect
const restrictSelect = document.querySelectorAll('.js-select-max');

restrictSelect.forEach((element, index) => {
  const inputs = element.querySelectorAll('.custom-checkbox input');
  const max = parseInt(element.getAttribute('data-select-max'));
  inputs.forEach((input, i) => {
    input.addEventListener('click', (e) => {
      const checked = Array.from(inputs).filter(elem => elem.checked);
      if(checked.length > max){
        e.preventDefault();
      }
    });
  });
});

const decimalInputs = document.querySelectorAll('[data-decimal]');
decimalInputs.forEach((inputElement) => {
  inputElement.addEventListener('change', (event) => {
    let num = event.target.value * 1;
    if (isNaN(num)) {
      num = event.target.getAttribute('data-decimal') * 1 || 1;
    }
    event.target.value = num.toFixed(1);
  });
});

const integerInput = document.querySelectorAll('[data-int]');
integerInput.forEach((inputElement) => {
  inputElement.addEventListener('change', (event) => {
    let num = parseInt(event.target.value);
    if (isNaN(num)) {
      num = parseInt(event.target.getAttribute('data-int')) * 1 || 1;
    }
    event.target.value = num;
  });
});
